.no-scrollbar
{
	&::-webkit-scrollbar-track
	{
		border-right: 0;
		border-left: 0;
		border-radius: 0;
		-webkit-box-shadow: none;
		-webkit-box-shadow: inset 0 0 12px rgba(0,0,0,.3);

		background-color: transparent;
	}

	&::-webkit-scrollbar
	{
		width: 0;

		background-color: none;
	}

	&::-webkit-scrollbar-thumb
	{
		border-right: 0;
		border-left: 0;
		border-radius: 0;

		background-color: transparent;
	}
}

.bg-red {
  background-color: $red;
}
