.onde-estamos {
  background-color: #040707;
  color: #fff;
  &__title {
    font-family: 'DIN-Black', sans-serif;
    font-size: convertREM(32);
    line-height: 1;
  }
  &__subtitle {
    font-family: 'DIN-Black', sans-serif;
    font-size: convertREM(20);
    line-height: 1;
  }
  span  {
    color: $red;
  }
}
