.delivery {
  background: #020202;
  color: #fff;
  &__title {
    font-family: 'DIN-Black', sans-serif;
    font-size: convertREM(32);
    line-height: 1;
  }
  &__subtitle {
    font-family: 'DIN-Black', sans-serif;
    font-size: convertREM(24);
    line-height: 1;
  }
}
