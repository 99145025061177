html {
  font-size: 16px;
}

body {
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  overflow-x: hidden;
  font-family: 'DIN-Regular', sans-serif;
  color: #231F20;
}

a {
  color: #231F20;
}

strong, b {
  font-weight: 700;
}

.modal-title {
  font-family: 'DIN-Black';
  font-size: 1.1rem;
}

.modal-subtitle {
  font-size: .98rem;
  line-height: 1.1;
}
