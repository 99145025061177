.button {
  color: #fff;
  background: #020202;
  padding: 1.2rem 1.5rem;
  text-decoration: none;
  line-height: 1;
  font-family: 'DIN-Black', sans-serif;
  &:hover {
    color: #fff;
  }
}
