.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
  @include media-breakpoint-up(md) {
    height: 80vh;
  }
  overflow: hidden;
  position: relative;
  &__bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
    object-position: bottom;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__logo {
    width: 300px;
    z-index: 2;
    position: relative;
  }
  &:after {
    /*
    content: '';
    position: absolute;
    background: linear-gradient(to top,rgba(0,0,0,1),rgba(0,0,0,0));
    background-size: 100% 50%;
    background-position: 0 100%;
    background-repeat: no-repeat;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: 1;*/


  }
}
