footer {
  background-color: #414042;
  color: #fff;
  font-size: convertREM(18);
  a {
    color: #fff;
    text-decoration: none;
    &:hover, &:focus {
      color: #fff;
    }
  }
  small {
    font-size: convertREM(13);
  }
}
