@font-face {
  font-family: 'DIN-Black';
  src: url('../fonts/DIN-Black.woff2') format('woff2'),
      url('../fonts/DIN-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'DIN-Regular';
  src: url('../fonts/DIN-Regular.woff2') format('woff2'),
      url('../fonts/DIN-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
